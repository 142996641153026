import { Injectable } from '@angular/core'
import { MessageService } from 'primeng/api'
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private messageService: MessageService
  ) {
    super(router, keycloak)
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      })
    }

    const requiredRoles = route.data['roles']

    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true
    }

    const hasRole = requiredRoles.some((role) => this.keycloak.getUserRoles().includes(role))

    if (hasRole) {
      return true
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Accès refusé',
        detail: "Vous n'avez pas accès à cette page.",
      })
      return false
    }
  }
}
